const Footer = () => {


    return (
      <div className="footer">
        <div className="inner">
          <ul>
            <li>
              <a href="/privacy">About</a>
            </li>
            <li>
              <a href="/privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="/privacy">Terms of Use</a>
            </li>
          </ul>
        </div>
      </div>
    );
}

export default Footer;