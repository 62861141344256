/* eslint-disable */
const AppStoreLinks = () => {
  return (
    <div className="app-store-links pull-right">
      <div className="inner">
        <ul >
          <li className="google-play">
            <a href="/apple">&nbsp;</a>
          </li>
          <li className="apple">
            <a href="/apple">&nbsp;</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppStoreLinks;
