
import { useState } from 'react';
import './assets/css/App.css';
import Logo from './components/Logo';
import CallToAction from './components/CallToAction';
import AppStoreLinks from "./components/AppStoreLinks";
import EmailInput from './components/EmailInput';
import Footer from "./components/Footer";
import PreviewGraphic from './components/PreviewGraphic';
import { validEmail } from './util';
import { joinWaitlist } from "./util/firebase";

function App() {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const emailSignup = (email) => {
  
    if (email) {
    joinWaitlist(email);
      setEmailSubmitted(true);
    }
  }
  return (
    <div className="App">
      <div className="background-layer">
        <PreviewGraphic />
        <div className="overlay">&nbsp;</div>
      </div>
      <div className="container">
        <header>
          <Logo />
          <AppStoreLinks />
          <div style={{ clear: "both" }}></div>
        </header>
        <div className="main">
          <div className="cta">
            <CallToAction />
            <EmailInput action={emailSignup} submitted={emailSubmitted} />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default App;
