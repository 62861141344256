const PreviewGraphic = () => {
  return (
    <div className="preview-graphic">
    
        <canvas id="canvas3d"></canvas>
     
    </div>
  );
};

export default PreviewGraphic;
