

const CallToAction = () => {

    return (
      <div className="cta-copy">
        <div className="headline"></div>
        <p>
       Bet on your beliefs on the world's first pocket prediction market.
        </p>
      </div>
    );
}

export default CallToAction;