
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDRA8aIOiPLTOe-lV7idXNIJXrst9I7ZT4",
  authDomain: "beta-saur.firebaseapp.com",
  projectId: "beta-saur",
  storageBucket: "beta-saur.appspot.com",
  messagingSenderId: "213474544967",
  appId: "1:213474544967:web:be741018193dc113d31062",
  measurementId: "G-P0TCT33T53",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore();
const functions = getFunctions(app);
if (
  window.location.hostname.match(
    /localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi
  )
) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const joinWaitlist = (email) => {
    

var addMessage = httpsCallable(functions, "addToWaitlist");
addMessage({ "email": email }).then((result) => {
    console.log(result);
    // Read result of the Cloud Function.
      var sanitizedMessage = result.data.text;
      console.log(sanitizedMessage);
  });

}


export { joinWaitlist };