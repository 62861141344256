
import { useState } from 'react';


const EmailInput = ({ action, submitted }) => {

  const [email, setEmail] = useState('');
  
  const handleChange = (e) => {
    console.log(e.target.value);
    setEmail(e.target.value);
  }
    return (
      <div>
        {!submitted ? (
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              onChange={handleChange}
              name="email"
              placeholder="Type your email"
            />
            <button
              onClick={() => {
                action(email);
              }}
              className="btn btn-primary"
            >
              Get Early Access
            </button>
          </div>
        ) : (
          <h1>Boom! You're in.</h1>
        )}
      </div>
    );
}

export default EmailInput;